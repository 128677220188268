<template>
  <div class="row mx-0 flex-column w-100">
    <div
      class="row mx-0 flex-nowrap channel-item w-100"
      :class="{'active': isActiveChannel}"
    >
      <div
        class="col-11 row px-0 mx-0 flex-nowrap"
        @click="playChannel()"
      >
        <div
          class="col-1 pr-0 d-flex justify-content-start align-items-center"
        >
          {{ item.chid }}
        </div>
        <div
          :class="{'col-8': !canPlay, 'col-11': canPlay}"
          class="d-flex flex-nowrap justify-content-center align-items-center"
        >
          <responsive-image
            class="channel-item-poster"
            aspect-ratio="1x1"
            img-class="channel-item-image"
            :src="item.poster || item.backdrop"
          />
          <div
            class="channel-item-text d-flex flex-column justify-content-center align-items-start pl-3"
          >
            <span class="channel-item-title text-truncate">{{ channelTitle }}</span>
            <div
              v-if="eventTime"
              class="channel-item-eventtime text-truncate text-muted"
            >
              {{ eventTime }}
            </div>
          </div>
        </div>
        <div
          v-if="!canPlay"
          class="col-3 px-0 d-flex justify-content-center align-items-center channel-item-subscribe"
        >
          <subscribe-label class="d-block"/>
        </div>
      </div>

      <div
        class="col-1 px-0 channel-item-arrow-area d-flex justify-content-center align-items-center"
        :class="{'open': showEpg, 'text-muted disabled': !currentEvent || currentEvent.id === undefined}"
        @click="toogleEpg()"
      >
        <span
          class="channel-item-arrow-icon"
        >▼</span>
      </div>
    </div>
    <transition
      name="fade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="showEpg"
        key="show"
        class="animated row mx-0 channel-epg-list mw-100 w-100"
      >
        <template v-if="isEpgLoaded">
          <template v-if="filteredEpg.length">
            <div
              v-for="(e_item, e_index) in filteredEpg"
              :key="e_index"
              class="col-12 px-0"
            >
              <!-- <div
                v-if="isNextDay(e_item,e_index)"
                class="day-line"
              >
                <div class="row">{{ getFormatedDate(e_item,e_index) }}</div>
              </div> -->
              <channel-epg-item :item="e_item"/>
            </div>
          </template>
          <div
            v-else
            class="d-flex align-items-center justify-content-center"
          >
            Not Found!
          </div>
        </template>

        <div
          v-else
          class="d-flex align-items-center full-space justify-content-center py-4"
        >
          <i class="fas fa-3x fa-spinner fa-pulse"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  // import ProductsService from 'src/store/products';
  import Config from 'src/service/config';
  import contentLanguage from 'src/filters/contentLanguage.js';
  import ChannelEpgItem from './channel-epg-item';
  import ResponsiveImage from 'src/components/reponsive-image.vue';
  import TrimEl from 'src/components/common/trim-el.vue';
  import EpgService from 'src/store/epg/index.js';
  import SubscribeLabel from 'src/components/subscription-label.vue';
  import formatDate from 'src/filters/formatDate.js';
  import { amsClient } from '../../service/ams';


  export default {
    name: 'ChannelItem',
    components: {
      ChannelEpgItem,
      ResponsiveImage,
      TrimEl,
      SubscribeLabel,
    },
    props: {
      eventIdentifier: {
        type: Number,
        default: 0,
      },
      // activeChannel: {
      //   type: String
      // },
      index: {
        type: Number,
        default: 0,
      },
      item: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        showEpg: false,
        isEpgLoaded: false,
        isFirstLoading: true,
      };
    },
    computed: {
      channelTitle() {
        return this.currentEvent && this.currentEvent.title
          ? contentLanguage(this.currentEvent, 'title')
          : contentLanguage(this.item, 'title');
      },
      eventTime() {
        if (
          !this.currentEvent ||
          !this.currentEvent.startDateTime ||
          !this.currentEvent.endDateTime
        ) {
          return null;
        }
        return `${formatDate(
          this.currentEvent.startDateTime,
          'HH:mm',
          false,
        )}-${formatDate(this.currentEvent.endDateTime, 'HH:mm', false)}`;
      },
      isActiveChannel() {
        let active = this.activeChannel === this.item.id;
        if (this.activeChannel && active && this.isFirstLoading) {
          this.scrollIntoView();
        }
        if (this.activeChannel) {
          this.isFirstLoading = false;
        }
        return active;
      },

      activeChannel() {
        let item = EpgService.getActiveChannel();
        return item ? item.id : null;
      },
      canPlay() {
        if (this.item) {

          // return (!this.item.not_free && !amsClient.isContentFreemium(this.item) && !amsClient.isContentPremium(this.item)) || (amsClient.isContentFreemium(this.item) && amsClient.canPlayFreemium(this.item)) || (amsClient.isContentPremium(this.item) && amsClient.canPlayPremium(this.item));
          return amsClient.canPlay(this.item);
        }
        return true;
      },

      filteredEpg() {
        let tomorow = new Date().getDate() + 1;
        // NOTE: hack for changing epg by click
        if (this.showEpg) {
          return this.epg.filter(el => el.endDateTime >= Date.now() && new Date(el.startDateTime).getDate() !== tomorow);
        }
        return [];
      },
      currentEvent() {
        let it = EpgService.getCurrentEpg(this.item.id);
        return it;
      },
      epg() {
        let it = EpgService.getEpgByChannel(this.item.id);
        return it;
      },

    },
    mounted() {
      this.event_checkItemStatus();
      this.scrollIntoView();
    },
    methods: {
      scrollIntoView() {
        this.$nextTick(() => {
          // NOTE: delete logic not scroll on mobile
          if (this.isActiveChannel) {
            this.$el.scrollIntoView(true);
          }
        });
      },

      event_checkItemStatus() {
        // ProductsService.setStateParams({isAuth: AuthService.isAuth() && !AuthService.isGuest()});
        //
        // ProductsService.getProductListDebounce();
      },
      playChannel() {
        if (this.activeChannel === this.item.id) {
          return;
        }

        Config.set('temp.context', 'user_click');
        EpgService.setActiveChannel(this.item);
        return false;
      },

      toogleEpg() {
        if (!this.currentEvent.id) {
          return;
        }
        this.showEpg = !this.showEpg;
        if (this.showEpg && !this.isEpgLoaded) {
          this.preloadEpg();
        }
      },


      preloadEpg() {
        EpgService.getEpgById({ id: this.item.id }).then(data => {
          this.$nextTick(() => {
            // this.epg = epgData;
            this.isEpgLoaded = true;
          });
        });
      },
    },
  };
</script>

<style lang="scss">
	.channel-item-arrow {
		&-area {
			background-color: transparent;
			color: $text-color;

			// &.disabled {
			//   color: lightgray;
			// }
			// &:not(.disabled):hover {
			//   cursor: pointer;
			//   color: grey;
			//   background-color: rgba(0, 0, 0, 0.2);
			// }
			transition: background-color 0.3s ease, opacity 0.3s ease, color 0.3s ease;

			&.disabled {
				pointer-events: none;
			}

			&:hover,
			&:focus {
				background-color: rgba(0, 0, 0, 0.2);
			}

			&.open {
				.channel-item-arrow-icon {
					transform: rotate(180deg);
					transition: transform 0.3s ease-in-out;
				}
			}
		}

		&-icon {
			transform: rotate(0);
			transition: transform 0.3s ease-in-out;
		}
	}

	.channel-item {
		color: $base-link-color;
		font-size: 0.8em;

		&:hover {
			cursor: pointer;
		}

		&.active {
			border-bottom: 2px solid $base-link-hover;
		}

		&:hover {
			background-color: $base-link-hover;
		}

		&-poster {
			flex-basis: 48px;
			height: 100%;
			max-height: 48px;
			max-width: 48px;
			width: 100%;
		}

		&-image {
			height: auto;
			// margin-top: 50%;
			// transform: translateY(-50%);
			// max-height: 48px;
			max-width: 48px;
			width: 100%;
		}

		&-text {
			flex-basis: 0;
			flex-grow: 1;
			min-width: 0;
		}

		// &-eventtime {
		//   font-size: 0.8em;
		// }

		&-subscribe {
			font-size: 0.8em;
		}

		&-title {
			max-width: 100%;
		}
	}


	.channel-epg-list {
		/*background-color: rgba(188, 208, 67, 0.51);*/
		padding: 0;

		.day-line {
			padding: 0 20px;

			.row {
				font-size: 14px;
				font-weight: 600;
				padding-left: 15px;

				padding-top: 8px;
			}
		}

		.epg-item,
		.day-line {
			align-self: center;

			border-bottom: 1px solid gray;
			height: 35px;

			.row:first-child {
				height: 100%;
			}
		}
	}

</style>

